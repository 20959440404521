import React, {
  FormEvent,
  FormEventHandler,
  useContext,
  useState,
} from 'react';
import { postAddJobWithZip } from '../services/job-api';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import { NavigateToPageRoute } from '../utils/common-functions';
import { useNavigate } from 'react-router-dom';
import { AddJobPayload } from '../models/job/AddJobPayload';
import { uploadFileForJob } from '../services/job-api';
export const useAddNewJobWithZip = (
  token: string,
  setError: React.Dispatch<React.SetStateAction<string>>,
  fileType: string,
  invoiceType: string,
  networkProvider: string,
  serviceProvider: string,
  companyName: string,
  companyId: string,
  userId: string,
) => {
  const [queryInProgress, setQueryInProgress] = useState<boolean>(false);
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [fileName, setFileName] = React.useState<string>('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e: FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault(); // avoid double submission
    if (fileBlob) {
      console.debug('Uploading file...');
      const formData = new FormData();
      // take the fileName without the full path:
      const myFileName = fileName.split('\\').pop();
      formData.append('file', fileBlob as Blob, myFileName);

      // Submit form
      await uploadFileForJob(userId, companyId.toString(), formData);
      await addNewJobWithZip();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setQueryInProgress(true);
    const file = event.target.files?.[0];
    if (file && file.type === 'application/zip') {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target?.result as ArrayBuffer;
        const blob = new Blob([arrayBuffer]);
        setFileBlob(blob);
        console.debug('Blob created:', blob);
        setQueryInProgress(false);
      };
      reader.readAsArrayBuffer(file);
    } else {
      setError('Invalid file type.');
      setQueryInProgress(false);
    }
  };

  const addNewJobWithZip = async () => {
    setQueryInProgress(true);

    const payload: AddJobPayload = {
      token: token,
      fileType: fileType,
      invoiceType: invoiceType,
      networkProvider: networkProvider,
      serviceProvider: serviceProvider,
      clientName: companyName,
      customerCompanyId: parseInt(companyId),
      userId: userId,
    };
    const response = await postAddJobWithZip(payload);
    setQueryInProgress(false);

    if (response.success) {
      const message = 'Job added successfully.';
      NavigateToPageRoute(
        `/rating-jobs?showMessage=${message}`,
        setCurrentRoute,
        navigate,
      );
    } else {
      setError(response.userMessage);
    }
  };

  return {
    handleFileChange,
    handleSubmit,
    queryInProgress,
    setQueryInProgress,
    fileName,
    setFileName,
  };
};
