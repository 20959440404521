import React from 'react';

export interface ValueAndOption {
  value: number | string;
  option: string;
}

interface SelectInputProps {
  label: string;
  value: number | string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  width?: string;
  valuesAndOptions: ValueAndOption[];
  custom_test_id?: string;
}

export const SelectInput: React.FC<SelectInputProps> = ({
  label,
  value,
  onChange,
  disabled = false,
  width,
  valuesAndOptions,
  custom_test_id,
}) => {
  return (
    <div key={'select-input-div_' + label} className={'form-control ' + width}>
      <label>
        <div className="label">
          <span className="label-text">{label}</span>
        </div>
        <select
          key={'select-input_' + label}
          className="input input-bordered input-primary block w-full"
          value={value}
          onChange={onChange}
          disabled={disabled}
          data-testid={custom_test_id}
        >
          {valuesAndOptions.map(({ value, option }: ValueAndOption) => {
            return (
              <option key={value} value={value}>
                {option}
              </option>
            );
          })}
        </select>
      </label>
    </div>
  );
};
