import React, { useContext } from 'react';
import { checkTokenAndRenderCode } from '../utils/common-functions';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../reusable-components/AuthContext';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import { useAddNewCompany } from '../hooks/add-new-company';
import { TextInput } from '../reusable-components/TextInput';

export const AddCompanyPage = () => {
  const [error, setError] = React.useState<string>('');
  const [companyName, setCompanyName] = React.useState<string>('');
  const [companyStreet, setCompanyStreet] = React.useState<string>('');
  const [houseNumber, setHouseNumber] = React.useState<string>('');
  const [zipCode, setZipCode] = React.useState<string>('');
  const [city, setCity] = React.useState<string>('');
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const { queryInProgress, handleAddCompany } = useAddNewCompany(
    token,
    setError,
    companyName,
    companyStreet,
    houseNumber,
    zipCode,
    city,
  );

  const pageJSX: JSX.Element = (
    <div className="md:container md:mx-auto px-10">
      <h1>Add New Company</h1>

      <TextInput
        label="Company Name:"
        placeholder="Company Name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setCompanyName(e.target.value)
        }
        required={true}
        value={companyName}
      />

      <TextInput
        label="Company Street:"
        placeholder="Company Street"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setCompanyStreet(e.target.value)
        }
        required={true}
        value={companyStreet}
      />

      <TextInput
        label="Street Number:"
        placeholder="Street Number"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setHouseNumber(e.target.value)
        }
        required={true}
        value={houseNumber}
      />

      <TextInput
        label="Zip Code:"
        placeholder="Zip Code"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setZipCode(e.target.value)
        }
        required={true}
        value={zipCode}
      />

      <TextInput
        label="City:"
        placeholder="City"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setCity(e.target.value)
        }
        required={true}
        value={city}
      />

      <div className="max-w-xs py-4 flex justify-center">
        <button
          className="w-full btn btn-primary w-40"
          onClick={handleAddCompany}
          data-testid="add-company-button"
          disabled={
            queryInProgress ||
            !companyName ||
            !companyStreet ||
            !houseNumber ||
            !zipCode ||
            !city
          }
        >
          Add Company
        </button>
      </div>
      <div className="max-w-xs flex justify-center">
        {queryInProgress ? (
          <div className="loading loading-spinner loading-lg w-10"></div>
        ) : (
          <div className="text-red-500" data-testid="add-company-error">
            {error}
          </div>
        )}
      </div>
    </div>
  );
  return checkTokenAndRenderCode(token, pageJSX, setCurrentRoute, navigate);
};
