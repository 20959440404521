import { GetUsersResponse } from '../models/user/GetUsersResponse';
import { UserLoginResponse } from '../models/user/UserLoginResponse';
import {
  sendFormDataClient,
  isAxiosError,
  unexpectedStatusCodeMessage,
} from './api-main';

export const errorMessages: Record<number, string> = {
  404: 'Incorrect username / password combination.',
  403: 'The user session could not be saved into the DB. Please log in again.',
  500: 'Server error while logging user in.',
  504: 'Server is not responding. Please try again later.',
};

const knownErrorCodes = Object.keys(errorMessages).map((k) => parseInt(k));

export const getUserLogin = async (
  userName: string,
  password: string,
): Promise<UserLoginResponse> => {
  try {
    const params = `username=${userName}&password=${password}`;
    const { data } = await sendFormDataClient.get(`/user/login?${params}`);
    return { token: data, success: true };
  } catch (error) {
    if (!isAxiosError(error)) {
      return {
        token: '',
        success: false,
        errorMessage: `Unexpected error while logging user in: ${error}`,
      };
    }

    // else is an axios error
    const myErrorCode: number = error.response?.status || 0;
    let errorMessage =
      unexpectedStatusCodeMessage(myErrorCode) +
      ` while logging user in: ${error}`;
    if (knownErrorCodes.includes(myErrorCode)) {
      errorMessage = errorMessages[error.response?.status as number];
    }
    return { token: '', success: false, errorMessage };
  }
};

export const getAllUsers = async (
  token: string,
): Promise<GetUsersResponse[]> => {
  try {
    const { data } = await sendFormDataClient.get(
      `/user/getUsers?token=${token}`,
    );
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
