import React, { createContext, useState, ReactNode } from 'react';

interface AuthContextProps {
  token: string;
  setToken: (token: string) => void;
}

const INVALID_TOKEN = 'invalid_token';

const initialAuthContext: AuthContextProps = {
  token: INVALID_TOKEN,
  setToken: () => {
    return;
  },
};

initialAuthContext.setToken('');

export const AuthContext = createContext<AuthContextProps>(initialAuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string>(initialAuthContext.token);

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};
