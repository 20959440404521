import React from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

interface DateRangePickerProps {
  label?: string;
  value: DateValueType;
  onChange: (
    value: DateValueType,
    e?: HTMLInputElement | null | undefined,
  ) => void;
}

export const DateRangePickerInput: React.FC<DateRangePickerProps> = ({
  label,
  value,
  onChange,
}) => (
  <label className="form-control w-50">
    <div className="label">
      <span className="label-text">{label}</span>
    </div>
    <div className="input input-bordered input-primary w-80 content-middle">
      <Datepicker value={value} onChange={onChange} placeholder={label} />
    </div>
  </label>
);
