import { NavigateFunction } from 'react-router-dom';
import React from 'react';
import { Slide, toast } from 'react-toastify';

/*
  Utility function Hook (capital case) to navigate to a page by route.
    - It sets the current index of the NavBar so it is synchronized with the page being displayed.
    - It uses the navigate function from react-router-dom to navigate to the route.
*/
export const NavigateToPageRoute = (
  route: string,
  setCurrentPage: (route: string) => void,
  navigate: NavigateFunction,
) => {
  // Call Hooks to update current page and navigate to route
  setCurrentPage(route);
  navigate(route);
};

/*
  Utility function to check if the token is present and render the JSX code accordingly.
    - If the token is present, it will render the JSX code.
    - If the token is not present, it will render a button to navigate to the login page.
*/
export const checkTokenAndRenderCode = (
  token: string,
  JSXCodeElement: JSX.Element,
  setCurrentRoute: (route: string) => void,
  navigate: NavigateFunction,
) => {
  const isTokenValid = token && token !== 'invalid_token'; // 'invalid_token' value is set when logging out
  return (
    <>
      {isTokenValid && JSXCodeElement}
      {!isTokenValid && (
        <div className="md:container md:mx-auto px-32 py-32">
          <button
            key="not-logged-in"
            data-testid="not-logged-in"
            className="btn btn-primary"
            onClick={() => NavigateToPageRoute('/', setCurrentRoute, navigate)}
          >
            Not logged in... click here to Login.
          </button>
        </div>
      )}
    </>
  );
};

export function isTokenValid(token?: string) {
  // This check is redundant on purpose
  const valid =
    token && token !== '' && token !== 'invalid_token' && token.length === 36;
  return valid;
}

export function isNumeric(value: string): boolean {
  return /^\d+$/.test(value);
}

export function isBlank(value: string): boolean {
  return !value || /^\s*$/.test(value);
}

export function showSuccessMessage(message: string) {
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Slide,
  });
}

export function showErrorMessage(message: string) {
  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Slide,
  });
}

export function showWarningMessage(message: string) {
  toast.warn(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Slide,
  });
}
