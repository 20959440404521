import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

interface CustomConfirmUIProps {
  title: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const CustomConfirmUI: React.FC<CustomConfirmUIProps> = ({
  title,
  message,
  onClose,
  onConfirm,
}) => {
  return (
    <div className="p-6 bg-white border-2 border-blue-500 rounded-lg shadow-lg">
      <h1 className="text-xl font-bold text-blue-700">{title}</h1>
      <p className="text-blue-600 my-4">{message}</p>
      <div className="flex justify-end space-x-4">
        <button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          className="btn bg-blue-500 text-white"
        >
          Yes
        </button>
        <button
          onClick={onClose}
          className="btn bg-white text-blue-500 border-blue-500"
        >
          No
        </button>
      </div>
    </div>
  );
};

export const showOperationConfirmation = (
  title: string,
  message: string,
  callback: () => void,
) => {
  confirmAlert({
    title: title,
    message: message,
    customUI: ({ title, message, onClose }): React.ReactNode => {
      return (
        <CustomConfirmUI
          title={title}
          message={message}
          onClose={onClose}
          onConfirm={callback}
        />
      );
    },
  });
};
