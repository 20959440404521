import { useContext, useState } from 'react';
import { StringOperationResponse } from '../models/base/StringOperationResponse';
import { isAxiosError } from 'axios';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import {
  NavigateToPageRoute,
  showSuccessMessage,
  showErrorMessage,
} from '../utils/common-functions';
import { useNavigate } from 'react-router-dom';
import { DeleteCompanyPayload } from '../models/company/DeleteCompanyPayload';
import { postDeleteCompany, postSearchCompany } from '../services/company-api';
import { CompanySearchResult } from '../models/company/CompanySearchResult';
import { CompanySearchPayload } from '../models/company/CompanySearchPayload';
import {
  seeBrowserConsoleMessage,
  sessionExpiredMessage,
} from '../services/api-main';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { showOperationConfirmation } from '../reusable-components/ConfirmDialog';

export const couldNotDeleteCompanyMessage = 'Could not delete company. ';
export const errorOnSearchCompaniesMessage =
  'An error occurred while searching for companies.';

export const useCompanySearch = (
  token: string,
  companyName: string,
  companyId: string,
  limitRecords: number,
) => {
  const [companies, setCompanies] = useState<CompanySearchResult[]>([]);
  const [queryInProgress, setQueryInProgress] = useState<boolean>(false);
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const searchCompanies = async () => {
    try {
      setCompanies([]);
      const payload: CompanySearchPayload = {
        token: token,
        name: companyName,
        companyId: companyId,
        limit: limitRecords,
      };

      setQueryInProgress(true);
      const result: CompanySearchResult[] = await postSearchCompany(payload);
      setQueryInProgress(false);
      setCompanies(result);
    } catch (error) {
      setQueryInProgress(false);
      if (isAxiosError(error) && error.response?.status === 403) {
        NavigateToPageRoute(
          `/?showMessage=${sessionExpiredMessage}`,
          setCurrentRoute,
          navigate,
        );
      } else {
        showErrorMessage(errorOnSearchCompaniesMessage);
      }
    }
  };

  const handleDeleteCompanyPressed = async (token: string, pk: string) => {
    const title = 'Confirm Delete Operation';
    const message = 'Are you sure you want to delete this company?';
    const callback = () => deleteCompany(token, pk);
    return showOperationConfirmation(title, message, callback);
  };

  const deleteCompany = async (token: string, pk: string) => {
    const payload: DeleteCompanyPayload = { token, companyPk: pk };
    const response: StringOperationResponse = await postDeleteCompany(payload);
    if (response.success) {
      const itemsFiltered = companies.filter((c) => c.companyPk !== pk);
      setCompanies(itemsFiltered);
      showSuccessMessage('Company deleted successfully.');
    } else {
      const title = couldNotDeleteCompanyMessage;
      if (response.userMessage) {
        showErrorMessage(title + response.userMessage);
      } else {
        showErrorMessage(title + seeBrowserConsoleMessage);
      }
    }
  };

  return {
    companies,
    queryInProgress,
    searchCompanies,
    handleDeleteCompanyPressed,
  };
};
