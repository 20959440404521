import React from 'react';
import { isBlank } from '../utils/common-functions';

interface TextInputProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  onEnterKeyPressed?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  width?: string;
  fieldType?: string;
  errorMessage?: string;
  required?: boolean;
  validation?: (value: string) => boolean;
  custom_test_id?: string;
}

const defaultValidation = (required: boolean) => (value: string) => {
  return !required || (required && !isBlank(value));
};

function validateConsideringBlank(
  value: string,
  required: boolean,
  validationFn: (value: string) => boolean,
): boolean {
  if (isBlank(value)) {
    return !required;
  }
  return validationFn(value);
}

export const TextInput: React.FC<TextInputProps> = ({
  label,
  value,
  onChange,
  placeholder = 'Type here',
  onEnterKeyPressed,
  disabled = false,
  width = 'w-1/4',
  fieldType = 'text',
  errorMessage = 'This field is incorrect or required',
  required = false,
  validation = defaultValidation(required),
  custom_test_id,
  ...rest
}) => {
  // state for keeping track of whether the input has been modified
  const [dirty, setDirty] = React.useState(false);

  const isValid = validateConsideringBlank(value, required, validation);

  const inputClass =
    isValid || !dirty
      ? 'input input-bordered input-primary w-full'
      : 'input input-bordered input-error bg-red-50 border border-red-500 text-red-900 placeholder-red-700 w-full';

  return (
    <label key={'text-input-div_' + label} className={'form-control ' + width}>
      <div className="label">
        <span className="label-text">{label}</span>
      </div>
      <input
        key={'text-input_' + label}
        data-testid={custom_test_id}
        type={fieldType}
        placeholder={placeholder}
        className={inputClass}
        onChange={(e) => {
          setDirty(true);
          onChange(e);
        }}
        onKeyDown={(e) =>
          e.key === 'Enter' && onEnterKeyPressed && onEnterKeyPressed(e)
        }
        disabled={disabled}
        {...(fieldType !== 'file' ? { value } : {})}
        {...rest}
      />
      <p className="mt-2 text-sm text-red-600 dark:text-red-500">
        <span className="font-medium">
          {isValid || !dirty ? '' : errorMessage}
        </span>
      </p>
    </label>
  );
};
