import { useCombobox } from 'downshift';
import React, { useRef } from 'react';
// import CleanIcon from '../icons/CleanIcon';

export class OptionElementType {
  value: string;
  title: string;
  description: string;
  constructor(value: string, title: string, description: string) {
    this.value = value;
    this.title = title;
    this.description = description;
  }
}

export type GenericSelectProps = {
  labelText: string;
  initialValue: OptionElementType;
  options: OptionElementType[];
  placeholder?: string;
  custom_test_id?: string;
  onChange?: (value: OptionElementType) => void;
};

function getElementsFilter(inputValue: string) {
  const lowerCasedInputValue = inputValue.toLowerCase();

  return function filter(option: OptionElementType) {
    return (
      !inputValue ||
      option.value.includes(lowerCasedInputValue) ||
      option.title.toLowerCase().includes(lowerCasedInputValue) ||
      option.description.toLowerCase().includes(lowerCasedInputValue)
    );
  };
}
export const GenericSearchableSelect: React.FC<GenericSelectProps> = ({
  options,
  placeholder,
  labelText,
  initialValue,
  onChange,
  custom_test_id,
}) => {
  const [items, setItems] = React.useState(options);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const comboboxRef = useRef<any>(null);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    selectedItem,
    selectItem, // function to select an item programmatically
    highlightedIndex,
    openMenu,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      setItems(options.filter(getElementsFilter(inputValue)));
    },
    items,
    itemToString(item) {
      return item ? item.title : '';
    },
    // toggleButtonId: `toggle-button_${labelText}`,
    onSelectedItemChange({ selectedItem }) {
      selectedItem ? onChange?.(selectedItem) : null;
    },
    initialInputValue: initialValue.title,
  });

  // Assign the combobox instance to the ref
  comboboxRef.current = { selectedItem, selectItem };

  function selectCurrentItem(selectedItem: OptionElementType | null) {
    comboboxRef.current.selectItem({
      value: selectedItem?.value ?? '',
      title: selectedItem?.title ?? '',
      description: selectedItem?.description ?? '',
    });
  }

  return (
    <div>
      <div className="w-1/2 flex flex-col gap-1">
        <label className="w-fit label form-control w-48" {...getLabelProps()}>
          <span className="label-text">{labelText}</span>
        </label>
        <div className="flex shadow-sm bg-white gap-0.5">
          {/* The searching input box */}
          <input
            id={`input_${labelText}`}
            data-testid={custom_test_id}
            placeholder={placeholder ?? 'Search...'}
            className="select select-primary w-full p-1.5"
            {...getInputProps()}
            onBlur={() =>
              // When leaving the input, select the item that matches the input value
              selectCurrentItem(selectedItem)
            }
            onFocus={() =>
              // When entering the input, clean the selection
              selectCurrentItem(null)
            }
            onClick={openMenu}
            {...getToggleButtonProps()}
          />
        </div>
      </div>
      <ul
        className={`absolute w-72 bg-white mt-1 shadow-md max-h-80 overflow-scroll p-0 z-10 ${
          !(isOpen && items.length) && 'hidden'
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              className="py-2 px-3 shadow-sm flex flex-col"
              key={item.value}
              style={
                highlightedIndex === index
                  ? { backgroundColor: 'lightgrey' }
                  : {}
              }
              {...getItemProps({ item, index })}
            >
              <span>{item.title}</span>
              <span className="text-sm text-gray-700">{item.description}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};
